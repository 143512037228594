@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'gordita';
  src: url('../public/fonts/gordita-bold-webfont.woff2') format('woff2'),
       url('../public/fonts/gordita-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;

}

@font-face {
  font-family: 'gordita';
  src: url('../public/fonts/gordita-medium-webfont.woff2') format('woff2'),
       url('../public/fonts/gordita-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'gordita';
  src: url('../public/fonts/gordita-regular-webfont.woff2') format('woff2'),
       url('../public/fonts/gordita-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;

}

body {
  margin: 0;
  font-family: 'gordita', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-black min-h-screen;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  outline: none;
}

.navbar-tab ul li{
  position: relative;
}
.navbar-tab ul li.active:after{
  content: "";
  width: 12px;
  height: 4px;
  @apply bg-secondary rounded-full;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.token-exchange button:hover{
  transform: rotate(180deg);
}

.react-numeric-input b{
  border-color: rgba(255, 255, 255, 0.1) !important;
  background: rgba(255, 255, 255, 0.1) !important;
  padding: 10px;
}
.react-numeric-input b i{
  background: #ffffff !important;
}

@media(max-width:767px){
  .price-range-box{
    grid-area: 2 / 1 / 3 / auto;
  }
  .liquidity-setting-modal .dropdown-content{
    right: 50%;
    transform: translateX(50%) !important;
    position: fixed;
  }
}